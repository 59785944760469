import React, { useState, useRef, useEffect } from "react";
import CardGalery from "../components/CardGalery.jsx";
import Footer from "../components/Footer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useSelector } from "react-redux";
import { useFirestoreConnect, useFirebase } from "react-redux-firebase";
import Header from "../components/Header";
import Video from "../components/Video.jsx";
import { makeStyles, Card, CardMedia, CardActionArea } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { BsImages } from "react-icons/bs";
import { MdMovie } from "react-icons/md";
import Navbar from "../components/Navbar";
import NavbarColorST from "../components/NavbarColorST";
import Spinner from "../components/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "75vh",
    backgroundImage: "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma1.jpg)",
    backgroundPosition: "center top",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
  },
  tab: { outline: "none !important", margin: "0 auto" },
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  imagen: {
    objectFit: "cover",
    height: "100%",
    minHeight: 200,
  },
  contentAlbum: {
    height: 250,
    overflow: "hidden",
    borderRadius: 5,
    boxShadow: "-1px 4px 5px -5px rgba(0,0,0,0.75)",
    "&:hover": {
      boxShadow: "-1px 4px 10px -5px rgba(0,0,0,0.75)",
      cursor: "pointer",
    },
  },
  line: {
    width: 100,
    height: 7,
    backgroundColor: "#0d6368",
    marginLeft: 0,
    marginTop: 0,
  },
  card: {
    display: "flex",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '75%'
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 300,
    height: 300,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Galeria = () => {
  const classes = useStyles();
  const [dataImage, setdataImage] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [bkColor, setBkColor] = useState(false);
  const [idAlbum, setIdAlbum] = useState(null);
  const firebase = useFirebase();
  const inputRef = useRef();
  const [dataImages, setDataImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  const companiesQuery = {
    collection: "albumfotos",
    /* doc: "f1fi0GUxyLaTKZarXlZR",
    subcollections: [{ collection: "images" }], */
  };

  useFirestoreConnect((props) => [companiesQuery]);

  let todos = useSelector((state) => state.firestore.ordered.albumfotos);

  useEffect(() => {
    if (todos !== undefined && todos.length !== 0) {
      if (todos[0].images !== undefined) {
        setdataImage(todos[0].images);
      } else {
        setdataImage(todos);
      }
    } else {
      setdataImage([]);
    }
  }, [todos]);

  useEffect(() => {
    if (idAlbum) {
      setLoading(true);
      setDataImages([]);
      let ref = firebase.storage().ref(`maac/albumfotos/${idAlbum}`);
      ref
        .listAll()
        .then((dir) => {
          dir.items.forEach((fileRef) => {
            var dirRef = firebase.storage().ref(fileRef.fullPath);
            dirRef.getDownloadURL().then(function (url) {
              dataImages.push({ url: url });
              setDataImages([...dataImages]);
              setLoading(false);
            });
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [dataImages, firebase, idAlbum]);

  const datavideo = [
    { url: "https://www.facebook.com/MAACCENTRAL/videos/523519648516218/" },
    { url: "https://www.facebook.com/MAACCENTRAL/videos/722718088220955/" },
    { url: "https://www.facebook.com/MAACCENTRAL/videos/722718088220955/" },
    { url: "https://www.facebook.com/MAACCENTRAL/videos/722718088220955/" },
    { url: "https://www.facebook.com/MAACCENTRAL/videos/2536482263136087/" },
  ];

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <Header title="Galería" />
      <div ref={inputRef} className={classes.root}>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="secondary"
          textColor="secondary"
          value={tabSelected}
          onChange={(event, someone) => {
            setTabSelected(someone);
            setDataImages([]);
          }}
        >
          <Tab
            label="IMAGENES"
            {...a11yProps(0)}
            className={classes.tab}
            icon={<BsImages style={{ fontSize: 25 }} />}
          />
          <Tab
            label="VIDEOS"
            {...a11yProps(1)}
            className={classes.tab}
            icon={<MdMovie style={{ fontSize: 25 }} />}
          />
        </Tabs>
        <TabPanel value={tabSelected} index={0} style={{ width: "100%" }}>
          <Typography variant="h3" color="textPrimary">
            <b>Álbumes</b>
            <hr className={classes.line} />
          </Typography>

          <Grid container alignItems="center" justify="space-around" spacing={3}>
            {dataImage.length > 0 ? (
              dataImage.map((character, index) => (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <CardActionArea
                    onClick={() => setIdAlbum(character.id)}
                    style={{ outline: "none" }}
                  >
                    <Card className={classes.card}>
                      <div className={classes.details}>
                        <Typography component="h5" variant="h5" align="center">
                          <b>{character.title}</b>
                        </Typography>
                      </div>
                      <CardMedia
                        className={classes.cover}
                        image={character.image}
                        title="Live from space album cover"
                      />
                    </Card>
                  </CardActionArea>
                </Grid>
              ))
            ) : (
              <Spinner />
            )}
          </Grid>
          {dataImages.length > 0 ? (
            <div style={{ marginTop: 50 }}>
              <Typography variant="h3" color="textPrimary">
                <b>Imágenes</b>
                <hr className={classes.line} />
              </Typography>
              <CardGalery
                data={dataImages}
                open={setisOpen}
                setphotoIndex={setphotoIndex}
              />
            </div>
          ) : (
            loading &&
            idAlbum && (
              <Typography
                variant="h6"
                color="textSecondary"
                style={{ marginTop: 50, fontSize: 13 }}
                align="center"
              >
                Aquí se mostrarán las imagenes del albún seleccionado.
              </Typography>
            )
          )}
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <Grid container spacing={2}>
            {datavideo.map((character, index) => (
              <Video url={character.url} />
            ))}
          </Grid>
        </TabPanel>
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={dataImages[photoIndex].url}
          nextSrc={dataImages[(photoIndex + 1) % dataImages.length].url}
          prevSrc={
            dataImages[(photoIndex + dataImages.length - 1) % dataImages.length]
              .url
          }
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex(
              (photoIndex + dataImages.length - 1) % dataImages.length
            )
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % dataImages.length)
          }
        />
      )}
      <Footer></Footer>
    </>
  );
};

export default Galeria;
