import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
} from "@material-ui/core";
import { GiMusicalScore, GiPencilBrush } from "react-icons/gi";
import { FaRegLightbulb } from "react-icons/fa";
import { BiFootball } from "react-icons/bi";


const useStyles = makeStyles((theme) => ({
  icon: {
    width: "50px",
  },
  text: {
    width: "calc((100%) - 60px)",
    padding: "0 10px",
  },
}));

const Actividades = (props) => {
  const { color } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} align="center">
          <br />
          <br />
          <Typography variant="h4" color="textPrimary">
            <b>Actividades extracurriculares</b>
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  <GiMusicalScore style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>Música y danza</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  <FaRegLightbulb style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>Talleres de matemática</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  <BiFootball style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>Juegos deportivos</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container alignItems="center">
                <Grid item className={classes.icon}>
                  <GiPencilBrush style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>Dibujo y pintura</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Actividades;
