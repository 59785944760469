import React, { useRef, useEffect, useState } from "react";
import Navbar from "../components/Navbar.jsx";
import NavbarColor from "../components/NavbarColor.jsx";
import Footer from "../components/Footer.jsx";
import Ofrecemos from "../components/Ofrecemos.jsx";
import Carousel from "../components/Carousel.jsx";
import CardComponent from "../components/CardComponent";
import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  secondDetail: {
    position: "relative",
    width: "100%",
    zIndex: 4,
    bottom: 120,
    maxWidth: "95%",
    margin: "0 auto",
  },
  background: {
    backgroundImage: "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma1.jpg)",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
}));
const Home = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();
  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < -495);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.menu}>
        {bkColor ? <NavbarColor /> : <Navbar />}
      </div>

      <div ref={inputRef}>
        <Carousel />

        <div className={classes.secondDetail}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="space-around"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={6} md={4} align="center">
              <Link to={{ pathname: "/inicial" }}>
                <CardComponent
                  title="INICIAL"
                  subtitle="Desarrollo motriz"
                  titleColor="#2f2f2f"
                  content="Fortalecemos los primeros años de vida para lograr el mejor desarrollo integral de nuestros niños." // El inicio de su etapa estudiantil, con una propuesta educativa enfocada en la estimulación del conocimiento y talento con el fin de lograr una formación integral.	
                  shadow="#ffb703"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <Link to={{ pathname: "/primaria" }}>
                <CardComponent
                  title="PRIMARIA"
                  subtitle="Asesoría para cada alumno"
                  titleColor="#2f2f2f"
                  content="Creemos en que cada alumno posee habilidades distintas, es por ello que con nuestra metodología apoyamos en el desarrollo de sus habilidades." // Se busca desarrollar a los alumnos a través de diversas actividades que favorecen su aprendizaje e inculcan diversos valores para su desarrollo personal.
                  shadow="#0087FF"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="center">
              <Link to={{ pathname: "/secundaria" }}>
                <CardComponent
                  title="SECUNDARIA"
                  subtitle="Rumbo a la universidad"
                  titleColor="#2f2f2f"
                  content="Formamos estudiantes aptos para el examen de admisión de las mejores universidades del país."  // En esta etapa buscamos consolidar el proceso de formación educativa y prepararlo para la universidad profundizando en las diversas áreas educativas.
                  shadow="#00E7CA"
                />
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Ofrecemos />

      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};
export default Home;
