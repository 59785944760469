import React, { useRef, useEffect, useState } from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import { Container, makeStyles, IconButton } from "@material-ui/core";
import DetailsLevel from "../components/DetailsLevel";
import NavbarColorST from "../components/NavbarColorST";
import Header from "../components/Header";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import primariap1 from "../assets/img/levels/primariap1.jpg";
import primariap2 from "../assets/img/levels/primariap2.jpg";
import primariap3 from "../assets/img/levels/primariap3.jpg";
import primariav1 from "../assets/img/levels/primariav1.jpg";
import primariav2 from "../assets/img/levels/primariav2.jpg";
import primariav3 from "../assets/img/levels/primariav3.jpg";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  secondDetail: {
    position: "relative",
    width: "100%",
    zIndex: 4,
    bottom: 120,
    maxWidth: "95%",
    margin: "0 auto",
  },
  background: {
    backgroundImage:
      "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma1.jpg)",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
  button: {
    position: "fixed",
    top: "45%",
    border: "2px solid #144677",
    color: "#144677",
    outline: "none !important",
  },
}));
const Primaria456 = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);

  const dataPrimaria = {
    color: "#4ecdc4",
    details: [
      "Aritmética",
      "Razonamiento matemático",
      "Álgebra",
      "Geometría",
      "Comunicación",
      "Razonamiento verbal",
      "Plan lector",
      "Ciencia y ambiente",
      "Personal social",
      "Inglés",
      "Computación",
      "Religión",
      "Educación física",
      "Danza",
    ],
    images: {
      fs_slider: [primariav1, primariav2, primariap1],
      sn_slider: [primariap1, primariap2, primariap3],
      tr_slider: [primariav3, primariav1, primariap2],
    },
    pensiones: {
      MATRÍCULA: "195.00",
      "PENSIÓN VIRTUAL": "195.00",
      "PENSIÓN SEMI-PRESENCIAL": "265.00",
      "PENSIÓN PRESENCIAL": "295.00",
    },
    brindamos: [
      "Plataforma personalizada (Intranet NapClass).",
      "Clases en vivo mediante zoom.",
      "Tutoría y seguimiento académico.",
      "Acompañamiento y comunicación permanente.",
      "Departamento psicológico.",
      "Círculo de estudios.",
      "Docentes especialistas en su área, responsables y empáticos.",
    ],
    plataformas: ["Zoom", "Whatsapp", "Napclass"],
  };

  const inputRef = useRef();
  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 200);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <Header title="Primaria" />
      <div ref={inputRef} className="mt-5 mb-5">
        <Container>
          <DetailsLevel
            details={dataPrimaria.details}
            images={dataPrimaria.images}
            color={dataPrimaria.color}
            pensiones={dataPrimaria.pensiones}
            brindamos={dataPrimaria.brindamos}
            plataformas={dataPrimaria.plataformas}
          />
        </Container>
        <Link to={{ pathname: "/inicial" }}>
          <IconButton className={classes.button} style={{ left: 10 }}>
            <RiArrowLeftSLine />
          </IconButton>
        </Link>
        <Link to={{ pathname: "/secundaria" }}>
          <IconButton className={classes.button} style={{ right: 10 }}>
            <RiArrowRightSLine />
          </IconButton>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
export default Primaria456;
