//Dependencies
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/Home";
import Galeria from "./pages/Galeria";
import Sedes from "./pages/Sedes";
import Admision from "./pages/Admision";
import Noticias from "./pages/Noticias";
import Nosotros from "./pages/Nosotros";
import Contacto from "./pages/Contacto";
import Inicial from "./pages/Inicial";
import Primaria from "./pages/Primaria";
import Secundaria from "./pages/Secundaria";

class Routes extends Component {
  onLogin() {
    // this.props.history.push("/")
  }
  render() {
    // const {location,match, history} = props;
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/galeria" component={Galeria} />
        <Route exact path="/sedes" component={Sedes} />
        <Route exact path="/admision" component={Admision} />
        <Route exact path="/noticias" component={Noticias} />
        <Route exact path="/nosotros" component={Nosotros} />
        <Route exact path="/contacto" component={Contacto} />
        <Route exact path="/inicial" component={Inicial} />
        <Route exact path="/primaria" component={Primaria} />
        <Route exact path="/secundaria" component={Secundaria} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
