import { DialogTitle, Typography, Dialog, DialogContent, Grid, Button } from "@material-ui/core";
import React from "react";

const SEDES = [
  {
    name: "RAFAEL HOYOS RUBIO",
    link: "https://napclass.pe/rhhoyos/login",
  },
  {
    name: "MARISCAL ANDRÉS AVELINO CÁCERES DE LA PERLA",
    link: "https://napclass.pe/thlaperla/login",
  },
  {
    name: "MARISCAL ANDRÉS AVELINO CÁCERES DE CARABAYLLO",
    link: "https://napclass.pe/horsancarabayllo/login",
  },
  {
    name: "MARISCAL CÁCERES EL TRIUNFADOR",
    link: "https://napclass.pe/thcarabayllo/login",
  },
  {
    name: "MARISCAL ANDRÉS AVELINO CÁCERES",
    link: "https://napclass.pe/khpinar/login",
  },
  {
    name: "MARISCAL ANDRÉS AVELINO CÁCERES DE NARANJAL",
    link: "https://napclass.pe/horsannaranjal/login",
  },
  {
    name: "MARISCAL ANDRÉS AVELINO CÁCERES DORREGARAY DE CAMPOY",
    link: "https://napclass.pe/rhcampoy/login",
  },
];

const ModalNapclass = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h5" align="center">
          Elige tu sede
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginBottom: "20px" }}>
        <Grid container spacing={2}>
          {SEDES.map((sede, index) => (
            <Grid item xs={12} key={index}>
              <Button
                variant="contained"
                color="primary"
                href={sede.link}
                target="_blank"
                fullWidth
                style={{ height: "100%", textAlign: "center" }}
                onClick={handleClose}
                size="large"
              >
                {sede.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalNapclass;
