import React from "react";
import {
  makeStyles,
  Breadcrumbs,
  Typography,
  Grid,
  Slide,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 225,
    padding: "0 0 0 10vw",
    backgroundImage: "linear-gradient(to right bottom, #144677, #144677, #144677, #144677, #144677, #144a7c, #144e81, #145286, #135b92, #10649e, #0a6daa, #0077b6)",
    position: "relative",
  },
  breadContent: {
    position: "absolute",
    bottom: 10,
    right: 50,
  },
  breadcrumbs: {
    color: "#f7fff7",
    "&:hover": {
      color: "#f7fff7",
    },
  },
  line: {
    width: 100,
    height: 7,
    backgroundColor: "#FFB703",
    marginLeft: 0,
    marginTop: 0,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <Grid
      container
      direction="column"
      justify="flex-end"
      alignItems="flex-start"
      className={classes.root}
    >
      <Slide
        direction="left"
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={1000}
      >
        <Grid>
          <Typography variant="h2" className="title_header">
            {title}
            <hr className={classes.line} />
          </Typography>
        </Grid>
      </Slide>
      <div className={classes.breadContent}>
        <Breadcrumbs separator="|" className={classes.breadcrumbs}>
          <Link className={classes.breadcrumbs} to={{ pathname: "/" }}>
            Inicio
          </Link>
          <Typography>{title}</Typography>
        </Breadcrumbs>
      </div>
    </Grid>
  );
};
export default Header;
