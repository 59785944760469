import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import "firebase/database";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import createStore from "./createStore";
import { combineReducers } from "redux";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.css";
import Routes from "./routes";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import * as serviceWorker from "./serviceWorker";

const fbConfig = {
  apiKey: "AIzaSyAV70Uen4XyqbOIJB7u0E4C6shHt-Om_IE",
  authDomain: "yachasun-49d1f.firebaseapp.com",
  databaseURL: "https://yachasun-49d1f.firebaseio.com",
  projectId: "yachasun-49d1f",
  storageBucket: "yachasun-49d1f.appspot.com",
  messagingSenderId: "140970216255",
  appId: "1:140970216255:web:de7bc4deef4b8b0ed0e18a",
};

try {
  firebase.initializeApp(fbConfig);
} catch (err) {}

firebase.firestore();
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: {
    userProfile: "users",
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <Routes />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
