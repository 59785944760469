import React, { useState } from "react";
import "video-react/dist/video-react.css";
import ReactPlayer from "react-player";
import { Grid, makeStyles } from "@material-ui/core";
import Spinner from "../components/Spinner";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
  },
  loader: {
    content: "",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  player: {
    maxWidth: "100% !important",
    height: "100%"
  },
}));

const Video = (props) => {
  const { url } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  return (
    <Grid item xs={12} md={6} xl={4} className={classes.content} align="center">
      <ReactPlayer
        controls={true}
        onReady={() => {
          setTimeout(() => setLoading(false), 1000);
        }}
        url={url}
        className={classes.player}
      />
      {loading && (
        <div className={classes.loader}>
          <Spinner />
        </div>
      )}
    </Grid>
  );
};

export default Video;
