import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Container, Grid, makeStyles } from "@material-ui/core";
import InicialAnimation from "../assets/img/INICIAL.png";
import PrimariaAnimation from "../assets/img/PRIMARIA.png";
import SecundariaAnimation from "../assets/img/SECUNDARIA.png";
import AdmisionItem from "../components/AdmisionItem";
import NavbarColorST from "../components/NavbarColorST";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
}));

const Admision = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <div style={{ overflow: "hidden" }}>
        <Header title="Admisión" />
        <br />
        <br />
        <br />
        <br />
        <div ref={inputRef}>
          <Container>
            <Grid container spacing={3}>
              <AdmisionItem
                title="INICIAL"
                animation={InicialAnimation}
                alternate={true}
              />
              <AdmisionItem
                title="PRIMARIA"
                animation={PrimariaAnimation}
                alternate={false}
              />
              <AdmisionItem
                title="SECUNDARIA"
                animation={SecundariaAnimation}
                alternate={true}
              />
            </Grid>
          </Container>
          <br /> <br /> <br /> <br /> <br /> <br />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Admision;
