import React, { useState } from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid, Hidden, Button } from "@material-ui/core";
import DialogMenu from "./DialogMenu";
import { MdMenu } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    margin: 0,
    padding: "20px 0",
    backgroundImage:
      "linear-gradient(to right bottom, #144677, #144677, #144677, #144677, #144677, #144a7c, #144e81, #145286, #135b92, #10649e, #0a6daa, #0077b6)",
  },
  title: {
    flexGrow: 1,
    color: "#f7fff7",
    padding: "40px auto",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "15px",
    "&:hover": {
      borderBottom: "1px solid #FFB703",
      boxShadow: "0 22px 15px -11px #FFB70350",
      transition: "250ms",
    },
  },
  schoolName: {
    color: "#f7fff7",
    fontWeight: "bold",
  },
  button: {
    border: "1px solid #FFB70330",
    backgroundColor: "#FFB70330",
    color: "#FFB703",
    "&:hover": {
      backgroundColor: "#FFB70310",
      color: "#FFB703",
    },
    "&:visited": {
      backgroundColor: "#FFB70310",
      color: "#FFB703",
    },
  },
}));

const NavbarColorST = (props) => {
  const { backgroundImage } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={classes.root}
        style={{ backgroundImage: backgroundImage }}
      >
        {/*   <Slide
          direction="down"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={1000}
        > */}
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={9}>
            <Link to="/">
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={4} sm={2} md={2}>
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item>
                      <img
                        src={logo}
                        style={{ height: "80px" }}
                        alt="logo maac"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8} sm={10} md={10}>
                  <Hidden mdUp>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item>
                        <Typography variant="p" className="schoolName_sm">
                          Institución Educativa Privada <br />
                          <span>MARISCAL ANDRÉS AVELINO CÁCERES</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item>
                        <Typography
                          variant="h5"
                          className="schoolName_lg"
                          align="left"
                        >
                          Institución Educativa Privada <br />
                          <span>MARISCAL ANDRÉS AVELINO CÁCERES</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Link>
          </Grid>

          <Grid item xs={3}>
            <Hidden mdDown>
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="black"
                    href="https://napclass.pe/maac/login"
                    className={classes.button}
                    target="_blank"
                  >
                    Napclass
                  </Button>
                </Grid>
                {/*   <Grid item>
                  <Button
                    variant="contained"
                    color="black"
                    href="https://napmatricula.com/mariscal-andres-avelino-caceres"
                    className={classes.button}
                    target="_blank"
                  >
                    Matrícula
                  </Button>
                </Grid> */}
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={(e) => setOpen(true)}
                  >
                    <MdMenu style={{ width: "20px", height: "20px" }} />
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>

          <Grid item xs={10}>
            <Hidden mdDown>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Link to="/" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Inicio
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/nosotros" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Nosotros
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/admision" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Admisión
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/galeria" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Galería
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/sedes" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Sedes
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/noticias" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Noticias
                    </Typography>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/contacto" style={{ marginLeft: "33%" }}>
                    <Typography
                      variant="p"
                      className={classes.title}
                      align="center"
                    >
                      Contacto
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          {/* <Hidden lgUp>
              <Grid item xs={4} style={{ border: "1px solid pink" }}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={(e) => setOpen(true)}
                >
                  <MdMenu style={{ width: "20px", height: "20px" }} />
                </Button>
              </Grid>
            </Hidden> */}
        </Grid>
        {/* </Slide> */}
      </div>

      {open && <DialogMenu open={open} setOpen={setOpen} />}
    </>
  );
};

export default NavbarColorST;
