import React from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography,
  Slide,
} from "@material-ui/core";
import { BiCheckDouble } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 30,
    color: "#0077B6",
  },
}));

const Admision = (props) => {
  const classes = useStyles();
  const { title, animation, alternate } = props;

  return alternate ? (
    <>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">
          <b>{title}</b>
        </Typography>
        <Slide direction="right" in={true} timeout={1000}>
          <List
            component="nav"
            subheader={
              <ListSubheader>
                <Typography variant="h6" color="textSecondary">
                  <b>Requisitos de admisión:</b>
                </Typography>
              </ListSubheader>
            }
          >
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Certificado de Estudios" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Partida de Nacimiento" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Ficha de Matrícula de SIAGIE (en caso de traslado)" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Resolución de Traslado" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Constancia de no adeudar" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Control de vacunas (copia)" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="D.N.I. (copia)" />
            </ListItem>
          </List>
        </Slide>
      </Grid>
      <Grid item xs={12} md={6} style={{ height: 450 }}>
        <Slide direction="left" in={true} timeout={1000}>
          <img src={animation}  width="100%" style={{maxWidth:500}} alt="animation" />
        </Slide>
      </Grid>
    </>
  ) : (
    <>
      <Grid item xs={12} md={6} style={{ height: 450 }}>
        <Slide direction="right" in={true} timeout={1000}>
        <img src={animation}  width="100%" style={{maxWidth:500}} alt="animation" />
        </Slide>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">
          <b>{title}</b>
        </Typography>
        <Slide direction="left" in={true} timeout={1000}>
          <List
            component="nav"
            subheader={
              <ListSubheader>
                <Typography variant="h6" color="textSecondary">
                  <b>Requisitos de admisión:</b>
                </Typography>
              </ListSubheader>
            }
          >
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Certificado de Estudios" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Partida de Nacimiento" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Ficha de Matrícula de SIAGIE (en caso de traslado)" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Resolución de Traslado" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Constancia de no adeudar" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Control de vacunas (copia)" />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <BiCheckDouble className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="D.N.I. (copia)" />
            </ListItem>
          </List>
        </Slide>
      </Grid>
    </>
  );
};

export default Admision;
