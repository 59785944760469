import React, { useRef, useEffect, useState } from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import {
  Container,
  makeStyles,
  IconButton,
  Hidden,
} from "@material-ui/core";
import DetailsLevel from "../components/DetailsLevel";
import NavbarColorST from "../components/NavbarColorST";
import Header from "../components/Header";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import inicialp1 from "../assets/img/levels/inicialp1.jpg";
import inicialp2 from "../assets/img/levels/inicialp2.jpg";
import inicialp3 from "../assets/img/levels/inicialp3.jpg";
import inicialv1 from "../assets/img/levels/inicialv1.jpg";
import inicialv2 from "../assets/img/levels/inicialv2.jpg";
import inicialv3 from "../assets/img/levels/inicialv3.jpg";
import "../assets/css/custom.scss";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  secondDetail: {
    position: "relative",
    width: "100%",
    zIndex: 4,
    bottom: 120,
    maxWidth: "95%",
    margin: "0 auto",
  },
  background: {
    backgroundImage:
      "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma1.jpg)",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
  button: {
    position: "fixed",
    top: "45%",
    border: "2px solid #144677",
    color: "#144677",
    outline: "none !important",
  },
}));

const dataInicial = {
  color: "#845EC2",
  details: [
    "Matemática",
    "Comunicación",
    "Plan Lector",
    "Grafomotricidad",
    "Personal Social",
    "Ciencia y Ambiente",
    "Inglés",
    "Psicomotricidad",
    "Arte",
    "Manualidades",
    "Minichef",
    "Baile Moderno",
  ],
  images: {
    fs_slider: [inicialv1, inicialv2, inicialp1],
    sn_slider: [inicialp1, inicialp2, inicialp3],
    tr_slider: [inicialv3, inicialv1, inicialp2],
  },
  pensiones: {
    "MATRÍCULA": "175.00",
    "PENSIÓN VIRTUAL": "175.00",
    "PENSIÓN SEMI-PRESENCIAL": "245.00",
    "PENSIÓN PRESENCIAL": "275.00",
  },
  brindamos: [
    "Plataforma personalizada (Intranet NapClass).",
    "Clases en vivo mediante zoom.",
    "Tutoría y seguimiento académico.",
    "Acompañamiento y comunicación permanente.",
    "Departamento psicológico.",
    "Charla y orientación constante para los padres de familia.",
    "Docentes especialistas en su nivel y área.",
  ],
  plataformas: ["Zoom", "Whatsapp", "Napclass"],
};

const colors = [
  "#fefc02",
  "#ffc700",
  "#ff9d00",
  "#ff5a05",
  "#ff2d00",
  "#cf2762",
  "#811e81",
  "#8536ff",
  "#1232ff",
  "#02f803",
  "#dffb03",
];

const Inicial = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 200);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <Header title="Inicial" />
      <div ref={inputRef} className="mt-5 mb-5">
        <div className="title">
          <h1>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              S
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              O
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              N
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              R
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              I
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              S
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              I
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              T
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              A
            </span>
            <span
              className="letter mr-4"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              S
            </span>
            <Hidden smUp>
              <br />
            </Hidden>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              D
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              E
            </span>
            <span
              className="letter mr-4"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              L
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              M
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              A
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              A
            </span>
            <span
              className="letter"
              style={{
                color: colors[Math.floor(Math.random() * colors.length)],
              }}
            >
              C
            </span>
          </h1>
        </div>
        <Container maxWidth="lg">
          <DetailsLevel
            color={dataInicial.color}
            details={dataInicial.details}
            images={dataInicial.images}
            pensiones={dataInicial.pensiones}
            brindamos={dataInicial.brindamos}
            plataformas={dataInicial.plataformas}
          />
        </Container>
        <Link to={{ pathname: "/primaria" }}>
          <IconButton className={classes.button} style={{ right: 10 }}>
            <RiArrowRightSLine />
          </IconButton>
        </Link>
      </div>
      <Footer />
    </div>
  );
};
export default Inicial;
