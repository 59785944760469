import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: '0 auto'
  },
  top: {
    color: "#046d6d",
    animationDuration: "250ms",
  },
}));

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        size={100}
        thickness={1}
      />
    </div>
  );
}
