import React from "react";
import { makeStyles, Slide } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import { Grid } from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { FaShieldAlt } from "react-icons/fa";
import { FaFootballBall } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi";
import { MdClass } from "react-icons/md";
import { FaChalkboardTeacher, FaUniversity } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  icons: {
    height: "35px",
    width: "35px",
    padding: "5px",
  },
}));

const Ofrecemos = () => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={10} lg={8}>
          <Typography variant="h4" component="h2" gutterBottom>
            <b>Propuesta Educativa</b>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Garantizar la felicidad de los alumnos es un aspecto prioritario en
            nuestro Colegio, combinarlo con una importante exigencia académica y
            la práctica de nuestra filosofía, es lo que genera un hábito de
            trabajo que los acompañará toda la vida. Los éxitos académicos y
            posteriormente profesionales, serán el resultado de nuestro lema
            "ESTUDIO, DISCIPLINA y TRABAJO”.
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "#144677" }}>
                  <FaShieldAlt className={classes.icons} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <Slide
                direction="left"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #1a535c` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Seguridad primero</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Más allá de cumplir la normativa vigente, nuestra Escuela
                      cuenta con las medidas de seguridad y comodidad óptimas
                      para nuestros alumnos.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  style={{ color: "#ffb703", borderColor: "#ffb703" }}
                  variant="outlined"
                >
                  <MdClass className={classes.icons} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <Slide
                direction="right"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #ffb703` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Perfil del alumno</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Formamos estudiantes con amplios saberes capaces de
                      desarrollarse en el ámbito científico y tecnológico, con
                      virtudes y solidos principios morales anclados en una vida
                      autentica y coherente.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "#000460" }}>
                  <FaChalkboardTeacher className={classes.icons} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <Slide
                direction="left"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #000460` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Maestros certificados</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Contamos con docentes capacitados permanentemente abiertos
                      a los nuevos enfoques educativos.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  style={{ color: "#ffb703", borderColor: "#ffb703" }}
                  variant="outlined"
                >
                  <FaUniversity className={classes.icons} />
                </TimelineDot>
                <TimelineConnector />
                <TimelineConnector />
              </TimelineSeparator>
              <Slide
                direction="right"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #ffb703` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Aulas Suficientes</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Las instalaciones y el equipamiento educativo fueron
                      implementados desde un inicio para ser un espacio
                      educativo amplio, con diversidad de ambientes y recursos
                      para el aprendizaje.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot style={{ backgroundColor: "#C70039" }}>
                  <FaFootballBall className={classes.icons} />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <Slide
                direction="left"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #C70039` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Instalaciones deportivas</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Nuestros alumnos crecen con el deporte disponiendo de
                      espacios adecuados para la práctica de todas las
                      modalidades de este tipo de actividades.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  style={{ color: "#4d908e", borderColor: "#4d908e" }}
                  variant="outlined"
                >
                  <GiSpellBook className={classes.icons} />
                </TimelineDot>
              </TimelineSeparator>
              <Slide
                direction="right"
                timeout={1000}
                in="true"
                mountOnEnter
                unmountOnExit
              >
                <TimelineContent>
                  <Paper
                    elevation={3}
                    className={classes.paper}
                    style={{ boxShadow: `1px 2px 10px -4px #4d908e` }}
                  >
                    <Typography variant="h6" component="h1">
                      <b>Lecciones creativas</b>
                    </Typography>
                    <Typography variant="p" color="textSecondary">
                      Materiales educativos que se adaptan a las capacidades de
                      cada alumno. Evaluaciones continuas presenciales y
                      virtuales.
                    </Typography>
                  </Paper>
                </TimelineContent>
              </Slide>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
    </>
  );
};

export default Ofrecemos;
