import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { Link } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { RiFacebookCircleFill } from "react-icons/ri";
import ModalNapclass from "./ModalNapclass";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  dialog: {
    "& .MuiPaper-root": {
      backgroundImage:
        "linear-gradient(to right bottom, #144677, #144677, #144677, #144677, #144677, #144a7c, #144e81, #145286, #135b92, #10649e, #0a6daa, #0077b6)",
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "white",
    "&:hover": {
      backgroundSize: "100% 2px",
    },
  },
  icons: {
    height: "35px",
    width: "35px",
    padding: "5px",
  },
  button: {
    margin: 20,
    padding: 10,
    backgroundColor: "#00000050",
    color: "#f7fff7",
    "&:hover": {
      backgroundColor: "#f7fff790",
      color: "#f7fff7",
    },
    "&:visited": {
      backgroundColor: "#000000",
      color: "#f7fff7",
    },
  },
  listItem: {
    padding: "25px",
    textAlign: "center",
    color: "#f7fff7",
    "&:hover": {
      backgroundColor: "#f7fff710",
      color: "#f7fff7",
    },
    "&:visited": {
      backgroundColor: "#f7fff7",
      color: "#f7fff7",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} />;
});

const DialogMenu = (props) => {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [modalNapclass, setModalNapclass] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ backgroundColor: "red" }}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={classes.dialog}
      >
        <AppBar style={{ background: "#14467770" }}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              MARISCAL ANDRÉS AVELINO CÁCERES
            </Typography>
            <Button onClick={(e) => setOpen(false)} className={classes.button}>
              <MdMenu style={{ width: "20px", height: "20px" }} />
            </Button>
          </Toolbar>
        </AppBar>
        <List style={{ marginTop: "80px" }}>
          <Link to="/" onClick={(e) => setOpen(false)}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Inicio" />
            </ListItem>
          </Link>

          <Divider />
          <Link to="/nosotros" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Nosotros" />
            </ListItem>
          </Link>

          <Divider />
          <Link to="/admision" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Admisión" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/galeria" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Galeria" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/sedes" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Sedes" />
            </ListItem>
          </Link>
          <Divider />
          <Link to="/noticias" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Noticias" />
            </ListItem>
          </Link>
          <Link to="/contacto" onClick={(e) => setOpen(false)} style={{ color: "white" }}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Contacto" />
            </ListItem>
          </Link>
          <Divider />
          <a
            target="_blank"
            onClick={() => setModalNapclass(true)}
            style={{ color: "white" }}
            rel="noopener noreferrer"
          >
            <ListItem className={classes.listItem}>
              <ListItemText primary="NAPCLASS" />
            </ListItem>
          </a>
          <Divider />
          {/* <a
            target="_blank"
            //href="https://napmatricula.com/mariscal-andres-avelino-caceres"
            style={{ color: "white" }}
            rel="noopener noreferrer"
          >
            <ListItem className={classes.listItem}>
              <ListItemText primary="MATRÍCULA" />
            </ListItem>
          </a>
          <Divider /> */}
          <ListItem className={classes.listItem}>
            <ListItemText>
              <a
                target="_blank"
                href="https://www.facebook.com/MAACCENTRAL"
                style={{ color: "white" }}
                rel="noopener noreferrer"
              >
                <RiFacebookCircleFill className={classes.icons} />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/Mariscalcaceres_oficial/"
                style={{ color: "white" }}
                rel="noopener noreferrer"
              >
                <RiInstagramFill className={classes.icons} />
              </a>
            </ListItemText>
          </ListItem>
        </List>
      </Dialog>

      {modalNapclass && <ModalNapclass open={modalNapclass} setOpen={setModalNapclass} />}
    </div>
  );
};

export default DialogMenu;
