import React, { useRef, useEffect, useState } from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import { Container, Grid, makeStyles, IconButton } from "@material-ui/core";
import DetailsLevel from "../components/DetailsLevel";
import NavbarColorST from "../components/NavbarColorST";
import Header from "../components/Header";
import { RiArrowLeftSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import secundariap1 from "../assets/img/levels/secundariap1.jpg";
import secundariap2 from "../assets/img/levels/secundariap2.jpg";
import secundariap3 from "../assets/img/levels/secundariap3.jpg";
import secundariav1 from "../assets/img/levels/secundariav1.jpg";
import secundariav2 from "../assets/img/levels/secundariav2.jpg";
import secundariav3 from "../assets/img/levels/secundariav3.jpg";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  secondDetail: {
    position: "relative",
    width: "100%",
    zIndex: 4,
    bottom: 120,
    maxWidth: "95%",
    margin: "0 auto",
  },
  background: {
    backgroundImage:
      "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma1.jpg)",
    backgroundPosition: "center center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
  button: {
    position: "fixed",
    top: "45%",
    border: "2px solid #144677",
    color: "#144677",
    outline: "none !important",
  },
}));
const Inicial = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);

  const dataSecundaria = {
    color: "#4d908e",
    details: [
      "Aritmética",
      "Razonamiento matemático",
      "Álgebra",
      "Geometría",
      "Trigonometría",
      "Comunicación",
      "Razonamiento verbal",
      "Física",
      "Química",
      "Ciencias sociales",
      "D.P.C.C",
      "Inglés",
      "Computación",
      "Religión",
      "Educación física",
      "Danza",
    ],
    images: {
      fs_slider: [secundariav1, secundariav2, secundariap1],
      sn_slider: [secundariap1, secundariap2, secundariap3],
      tr_slider: [secundariav3, secundariav1, secundariap2],
    },
    pensiones: {
      "MATRÍCULA": "210.00",
      "PENSIÓN VIRTUAL": "210.00",
      "PENSIÓN SEMI-PRESENCIAL": "290.00",
      "PENSIÓN PRESENCIAL": "310.00",
    },
    brindamos: [
      "Plataforma personalizada (Intranet NapClass).",
      "Clases en vivo mediante zoom.",
      "Tutoría y seguimiento académico.",
      "Acompañamiento y comunicación permanente.",
      "Departamento psicológico.",
      "Charla y orientación constante para los padres de familia.",
      "Docentes especialistas en su área, responsables y empáticos.",
    ],
    plataformas: ["Zoom", "Whatsapp", "Napclass"],
  };

  const inputRef = useRef();
  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 200);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <div className={classes.background}>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <Header title="Secundaria" />
      <div ref={inputRef} className="mt-5 mb-5">
        <Container>
          <DetailsLevel
            color={dataSecundaria.color}
            details={dataSecundaria.details}
            images={dataSecundaria.images}
            pensiones={dataSecundaria.pensiones}
            brindamos={dataSecundaria.brindamos}
            plataformas={dataSecundaria.plataformas}
          />
        </Container>
      </div>
      <Link to={{ pathname: "/primaria" }}>
        <IconButton className={classes.button} style={{ left: 10 }}>
          <RiArrowLeftSLine />
        </IconButton>
      </Link>
      <Footer />
    </div>
  );
};
export default Inicial;
