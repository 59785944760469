import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  CardActionArea,
  Zoom,
} from "@material-ui/core";
import Spinner from "../components/Spinner";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    outline: "none !important",
    "&:hover": {
      boxShadow: "0px 9px 10px -6px rgba(0,0,0,0.5)",
      transition: ".25s",
    },
    width: '100%',
    height: '100%'
  },
  imagen: {
    objectFit: "cover",
    width: '100%',
    height: '100%',
    borderRadius: 5
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: 200,
  },
}));

const CardGalery = (props) => {
  const classes = useStyles();
  const { data, open, setphotoIndex } = props;
  const [dataImages, setDataImages] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      setTimeout(() => {
        setDataImages(data);
      }, 2000);
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      {dataImages.length > 0 ? (
        dataImages.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Zoom in={true} style={{ transitionDelay: "1s" }}>
              <CardActionArea
                className={classes.content}
                onClick={() => {
                  setphotoIndex(index);
                  open(true);
                }}
              >
                <img src={event.url} className={classes.imagen} alt="imagen" />
              </CardActionArea>
            </Zoom>
          </Grid>
        ))
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

export default CardGalery;
