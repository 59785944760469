import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import { ImFacebook2 } from "react-icons/im";
import { TiSocialInstagram } from "react-icons/ti";
import { FiMail } from "react-icons/fi";
import { ImPhone } from "react-icons/im";
import Logo from "../assets/img/logo.png";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Maccito from "../assets/img/maacito.png";
import Maccita from "../assets/img/maacita.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#222222",
    padding: "100px 0",
    position: "relative",
  },
  itemOne: {
    width: 335,
    padding: 75,
  },
  itemTwo: {
    width: "calc(100% - 335px)",
    minWidth: 335,
    textAlign: "center",
  },
  lista: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "50px 0",
  },
  link: {
    color: "white",
    fontWeight: 700,
    "&:hover": {
      color: "#f52b1a",
    },
  },
  links: {
    color: "rgba(200,200,200,.7)",
    "&:hover": {
      color: "#f52b1a",
    },
  },
  social: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  finalDetail: {
    border: "1px solid rgba(200,200,200,.7)",
    padding: 5,
    fontSize: 30,
    color: "rgba(200,200,200,.7)",
    borderRadius: "50%",
    marginRight: 10,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [phone, setphone] = useState(null);
  const [correo, setcorreo] = useState(null);

  const newsQuery = {
    collection: "config",
    // doc: "f1fi0GUxyLaTKZarXlZR",
    // subcollections: [{ collection: "news" }],
  };
  useFirestoreConnect((props) => [newsQuery]);
  const todos = useSelector((state) => state.firestore.ordered.config);

  useEffect(() => {
    if (todos !== undefined && todos.length > 0) {
      setphone(todos[0].phone);
      setcorreo(todos[0].correo);
    }
  }, [todos]);

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item className={classes.itemOne}>
            <img src={Logo} width="100%" alt="logo maac" />
          </Grid>
          <Grid item className={classes.itemTwo}>
            <Typography variant="p" style={{ color: "rgba(200,200,200,.7)" }}>
              Institución Educativa Privada
            </Typography>
            <Typography variant="h4" style={{ color: "rgba(255,255,255,1)" }}>
              <b>MARISCAL ANDRÉS AVELINO CÁCERES</b>
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item style={{ width: "calc(100% - 335px)", minWidth: 335 }}>
                <div className={classes.lista}>
                  <Grid container spacing={5} justify="center">
                    <Grid item>
                      <Link className={classes.link} to={{ pathname: "/" }}>
                        INICIO
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        to={{ pathname: "/nosotros" }}
                      >
                        NOSOTROS
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        to={{ pathname: "/admision" }}
                      >
                        ADMISIÓN
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        to={{ pathname: "/galeria" }}
                      >
                        GALERÍA
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        to={{ pathname: "/sedes" }}
                      >
                        SEDES
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        className={classes.link}
                        to={{ pathname: "/noticias" }}
                      >
                        NOTICIAS
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item style={{ width: 325 }}>
                <div className={classes.social}>
                  <a
                    href="https://www.facebook.com/MAACCENTRAL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ImFacebook2
                      className={classes.link}
                      style={{ fontSize: 25 }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/Mariscalcaceres_oficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiSocialInstagram
                      className={classes.link}
                      style={{ fontSize: 35 }}
                    />
                  </a>
                </div>
                <br />
                <Typography
                  variant="p"
                  style={{ color: "rgba(200,200,200,.7)" }}
                >
                  &copy; {new Date().getFullYear()} Netapp Perú <br /> Todos los
                  derechos reservados
                </Typography>
              </Grid>
            </Grid>
            <fieldset style={{ borderTop: "3px solid rgba(200,200,200,.7)" }}>
              <legend
                style={{
                  width: 160,
                  color: "#f52b13",
                  fontSize: 30,
                  textAlign: "left",
                }}
              >
                <b>CONTACTO</b>
              </legend>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                {phone &&
                  phone.split(",").map((ev) => (
                    <span style={{ display: "flex" }}>
                      <ImPhone className={classes.finalDetail} />
                      <Typography
                        variant="p"
                        component="a"
                        href={`tel:${ev}`}
                        className={classes.links}
                      >
                        {ev}
                      </Typography>
                    </span>
                  ))}
                <span style={{ display: "flex" }}>
                  <FiMail className={classes.finalDetail} />
                  <Typography
                    variant="p"
                    component="a"
                    href={`mailto:${correo}`}
                    className={classes.links}
                  >
                    {correo}
                  </Typography>
                </span>
              </Grid>
            </fieldset>
          </Grid>
        </Grid>
      </Container>
        <img src={Maccito} className="maacito" />
        <img src={Maccita} className="maacita" />
    </footer>
  );
};

export default Footer;
