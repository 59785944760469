import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import NavbarColorST from "../components/NavbarColorST";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

const maps = [
  {
    id: "IHuCPzBlVxRWLoKsgxxz",
    name: "RAFAEL HOYOS RUBIO",
    url: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3901.479343138846!2d-77.024219!3d-12.079302!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c88f2748fb25%3A0x5296c98fe14208fc!2sAvenida%20de%20las%20Am%C3%A9ricas%20386%2C%20La%20Victoria%2015034!5e0!3m2!1ses!2spe!4v1621032652935!5m2!1ses!2spe",
  },
  {
    id: "UEu3oVTZSqL2y7jXfvDj",
    name: "Mariscal André Avelino Cáceres la Perla",
    url: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3901.6213875263884!2d-77.10640080513363!3d-12.06955055341275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105cbde44ed5a29%3A0x1bd732748d4cbd26!2sC.%20Lizardo%20Montero%20290%2C%20La%20Perla%2007011!5e0!3m2!1ses!2spe!4v1621029090191!5m2!1ses!2spe",
  },
  {
    id: "WHS05n6WctTs0IUAv7EE",
    name: "Mariscal Andrés Avelino Cáceres de Carabayllo",
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.02364731840268!2d-77.02253473308089!3d-11.878720780014726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105d0bacc848397%3A0xfa337239a64fb9a5!2sAv.%20Angamos%2C%20Carabayllo%2015318!5e0!3m2!1ses!2spe!4v1621029030288!5m2!1ses!2spe",
  },
  {
    id: "ncqncNDSxw86wPvHJVSL",
    name: "Mariscal Cáceres el Triunfador",
    url: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d580.4034593241432!2d-77.02347274508455!3d-11.872080825400152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2spe!4v1621029339393!5m2!1ses!2spe",
  },
  {
    id: "qUhd3QooCUrmjMzWW4tp",
    name: "COLEGIO MILITARIZADO MARISCAL ANDRES AVELINO CARECES",
    url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.8541775985873!2d-77.05813999855975!3d-11.91523489600745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105d1ac8fb7160b%3A0x2cd17164075ee865!2sColegio%20Militarizado%20Mariscal%20Andres%20Avelino%20C%C3%A1ceres!5e0!3m2!1ses!2spe!4v1621029408843!5m2!1ses!2spe",
  },
  {
    id: "sinSkjiIhYmfpUvBPif5",
    name: "Mariscal Andrés Avelino Cáceres de Naranjal",
    url: "https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d487.89957651672125!2d-77.0873537630824!3d-11.960885266584102!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2spe!4v1621032354685!5m2!1ses!2spe",
  },
  {
    id: "u08p0Vc1X9Md2rMvj8Bg",
    name: "Mariscal André Avelino Cáceres de Campoy",
    url: "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d398.51585149079705!2d-76.96321125588007!3d-12.01334558667509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses!2spe!4v1621032501646!5m2!1ses!2spe",
  },
];

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
}));

const Contacto = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();
  const [allSedes, setAllSedes] = useState([]);
  const [mapSelected, setMapSelected] = useState("");

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  const newsQuery = { collection: "sedes" };

  useFirestoreConnect((props) => [newsQuery]);

  const todos = useSelector((sedes) => sedes.firestore.ordered.sedes);

  useEffect(() => {
    if (todos && todos.length > 0) {
      let temp = [];
      for (let x = 0; x < todos.length; x++) {
        for (let i = 0; i < maps.length; i++) {
          if (todos[x].id == maps[i].id) {
            temp.push({
              index: x,
              name: todos[x].name,
              status: x === 0 ? true : false,
              url: maps[i].url,
            });
          }
        }
      }
      setMapSelected(maps[0].url);
      setAllSedes(temp);
    }
  }, [todos]);

  const changeSede = (dataSede) => {
    let result = [];
    for (let i = 0; i < allSedes.length; i++) {
      if (allSedes[i].index === dataSede.index) {
        result.push({
          index: allSedes[i].index,
          name: allSedes[i].name,
          status: true,
          url: allSedes[i].url,
        });
        setMapSelected(allSedes[i].url);
      } else {
        result.push({
          index: allSedes[i].index,
          name: allSedes[i].name,
          status: false,
          url: allSedes[i].url,
        });
      }
    }
    setAllSedes(result);
  };

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <div style={{ overflow: "hidden" }}>
        <Header title="Contacto" />
        <div
          ref={inputRef}
          style={{
            maxWidth: "2500px",
            margin: "0 auto",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={5} md={4} lg={3} xl={2}>
              <List>
                {allSedes.length > 0 &&
                  allSedes.map((ev) => (
                    <ListItem
                      button
                      selected={ev.status}
                      onClick={() => changeSede(ev)}
                      divider
                    >
                      <ListItemText
                        primary={
                          <Typography variant="body2" color="textSecondary">
                            <b>{ev.name.toUpperCase()}</b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={7} md={8} lg={9} xl={10}>
              <iframe
                src={mapSelected}
                style={{
                  width: "100%",
                  height: "calc((100vh) - 225px)",
                  border: "none",
                }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Contacto;
