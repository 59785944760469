import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar.jsx";
import Footer from "../components/Footer.jsx";
import Header from "../components/Header";
import {
  Container,
  Grid,
  ListItem,
  List,
  ListItemIcon,
  Typography,
  Card,
  CardContent,
  makeStyles,
  CardActionArea,
  Slide,
} from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { BiCheckDouble } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import VisionAnimation from "../assets/img/levels/secundariav2.jpg";
import MisionAnimation from "../assets/img/levels/secundariav1.jpg";
import { GoPlay } from "react-icons/go";
import { GiTrophyCup } from "react-icons/gi";
import { BsAward } from "react-icons/bs";
import { BiCalendarEvent } from "react-icons/bi";
import { VscPerson } from "react-icons/vsc";
import moment from "moment";
import NavbarColorST from "../components/NavbarColorST";
import valoresImage from "../assets/img/VALORES.png";
import "moment/locale/es";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
  animation: {
    height: 200,
    borderRadius: 20,
    boxShadow: "0 0 20px -5px",
  },
  avatar: {
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 25,
  },
  outline: {
    outline: "none !important",
  },
  sizeIcon: {
    fontSize: 30,
    color: "#4ecdc4",
  },
  background: {
    backgroundImage:
      "url(https://maacfiles.s3.us-east-2.amazonaws.com/imagenes/forma2.jpg)",
    backgroundPosition: "top center",
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
}));

const Nosotros = () => {
  const classes = useStyles();
  const dateStart = "1989-04-25";
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  let nacimiento = moment(dateStart);
  let years = moment().diff(nacimiento, "years");

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <div style={{ overflow: "hidden" }}>
        <Header title="Nosotros" />
        <br />
        <div ref={inputRef} className={classes.background}>
          <Container>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h5" color="textPrimary" align="center">
                  Institución Educativa Privada <br />
                  <b>“MARISCAL ANDRÉS AVELINO CÁCERES”</b>
                </Typography>
                <Timeline align="alternate">
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#1a535c" }}
                      >
                        <GoPlay />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="left"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #1a535c" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>NUESTROS INICIOS</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Fundada el{" "}
                                {moment(dateStart).format(
                                  "DD [de] MMMM [del año] YYYY"
                                )}{" "}
                                con los niveles de Educación Inicial, Primaria y
                                Secundaria.
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#000460" }}
                      >
                        <BsAward />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="right"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #000460" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>AÑOS DE EXPERIENCIA</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Contamos con {years} años de experiencia
                                formando a estudiantes que hoy por hoy son
                                distinguidos profesionales, deportistas y
                                miembros de las Fuerzas Armadas
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#4d908e" }}
                      >
                        <BsCheckCircle />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="left"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #4d908e" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>EDUCACIÓN DE CALIDAD</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Ofrecemos una educación de calidad brindando a
                                los estudiantes una formación integral,
                                rescatando los valores en nuestra sociedad.
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#4ecdc4" }}
                      >
                        <VscPerson />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="right"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #4ecdc4" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>FORMACIÓN MILITARIZADA</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Acompañado de una formación militarizada a cargo
                                de docentes altamente capacitados en diferentes
                                especialidades y niveles.
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#ffb703" }}
                      >
                        <GiTrophyCup />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="left"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #ffb703" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>LOGROS OBTENIDOS</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Logros en los diversos ámbito académicos,
                                culturales, cívicos, entre otros.
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot
                        className={classes.avatar}
                        style={{ backgroundColor: "#ff6b6b" }}
                      >
                        <BiCalendarEvent />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Slide
                        direction="right"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                        timeout={1000}
                      >
                        <Card
                          style={{ boxShadow: "1px 2px 10px -4px #ff6b6b" }}
                        >
                          <CardActionArea className={classes.outline}>
                            <CardContent>
                              <Typography variant="h6" color="textSecondary">
                                <b>EVENTOS IMPORTANTES</b>
                              </Typography>
                              <Typography variant="body1" color="textSecondary">
                                Engalanó en más de una ocasión, en los años 2005
                                y 2006, con su participación en la Gran Parada
                                Militar el 29 de Julio en la Av. Brasil
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Slide>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={false} md={6}>
                <Slide
                  direction="right"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <img src={valoresImage} width="100%" alt="about" />
                </Slide>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" color="textPrimary" align="center">
                  <b>VALORES</b>
                </Typography>
                <Slide
                  direction="left"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>DISCIPLINA </b>
                        Coordinación de actitudes, que permite desarrollar
                        habilidades para poder seguir un determinado código de
                        conducta y orden en la vida diaria.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>RESPETO </b>
                        Permite al ser humano reconocer, aceptar, apreciar y
                        valorar las cualidades del prójimo, reconoce su valor
                        propio y los derechos de los individuos de la sociedad.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>JUSTICIA </b>
                        Conjunto de reglas y normas que establece un marco
                        adecuado para las relaciones entre personas.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>IDENTIDAD NACIONAL </b>
                        Valor a la herencia nacional, practica las costumbres y
                        tradiciones de su país como propias, identificándose con
                        su nación.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>TOLERANCIA </b>
                        La capacidad de saber escuchar y aceptar a los demás,
                        valorando las distintas formas de entender y
                        posicionarse en la vida siempre que no atenten contra
                        los derechos fundamentales de la persona.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <BiCheckDouble className={classes.sizeIcon} />
                      </ListItemIcon>
                      <Typography color="textSecondary" variant="body2">
                        <b>SOLIDARIDAD </b>
                        Cuando dos o más personas se unen y colaboran mutuamente
                        para conseguir un fin común.
                      </Typography>
                    </ListItem>
                  </List>
                </Slide>
              </Grid>
              <Grid item xs={12} md={6}>
                <Slide
                  direction="right"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <Card>
                    <CardContent align="center">
                      <img
                        src={MisionAnimation}
                        className={classes.animation}
                        alt="animation"
                      />
                      <br />
                      <br />
                      <Typography gutterBottom variant="h5" component="h2">
                        <b>MISIÓN</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        Somos una institución que brinda una formación integral,
                        a través de un servicio de calidad en la formación de
                        capacidades y habilidades científico humanístico para
                        los niveles de inicial, primaria y secundaria.
                      </Typography>
                    </CardContent>
                  </Card>
                </Slide>
              </Grid>
              <Grid item xs={12} md={6}>
                <Slide
                  direction="left"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={1000}
                >
                  <Card>
                    <CardContent align="center">
                      <img
                        src={VisionAnimation}
                        className={classes.animation}
                        alt="animation"
                      />
                      <br />
                      <br />
                      <Typography gutterBottom variant="h5" component="h2">
                        <b>VISIÓN</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        Ser una institución reconocida por su alta calidad
                        educativa en inicial, primaria y secundaria que forje
                        ciudadanos líderes con una formación en capacidad
                        creativa, crítica y emprendedora.
                      </Typography>
                    </CardContent>
                  </Card>
                </Slide>
              </Grid>
              <Grid item xs={12}>
                <br />
              </Grid>
            </Grid>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Nosotros;
