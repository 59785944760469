import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  List,
  Slide,
  ListItem,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CheckIcon from "@material-ui/icons/Check";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Reforzamiento from "./Reforzamiento";
import Actividades from "./Actividades";
import NapClass from "../assets/img/napclass.png";
import Zoom from "../assets/img/zoom.png";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 50,
    color: "#25D366",
  },
}));

const DetailsLevel = (props) => {
  const classes = useStyles();
  const { color, details, images, pensiones, brindamos, plataformas } = props;

  return (
    <Slide direction="left" in={true} timeout={1000}>
      <Grid container spacing={1} className="mt-5">
        <Reforzamiento color={color} />
        <Grid item xs={12} md={6}>
          <Card style={{ boxShadow: `0 0 5px 0 ${color}`, height: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <List dense>
                    {details &&
                      details.map((ev, index) => (
                        <ListItem button>
                          <ListItemIcon>
                            <Avatar
                              style={{
                                color: color,
                                backgroundColor: "white",
                                border: `2px solid ${color}`,
                              }}
                            >
                              <DoneAllIcon />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography color="textSecondary" variant="body2">
                                {ev}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                  </List>
                </Grid>
                <Grid item xs={12} md={6} className="content_sliders">
                  {images.fs_slider && (
                    <div
                      className="slider"
                      style={{
                        border: `2px solid ${color}`,
                        boxShadow: `0 0 5px 0 ${color}`,
                      }}
                    >
                      <ul>
                        {images.fs_slider.map((ev) => (
                          <li style={{ backgroundImage: `url(${ev})` }}></li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {images.sn_slider && (
                    <div
                      className="slider"
                      style={{
                        border: `2px solid ${color}`,
                        boxShadow: `0 0 5px 0 ${color}`,
                      }}
                    >
                      <ul>
                        {images.sn_slider.map((ev) => (
                          <li style={{ backgroundImage: `url(${ev})` }}></li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {images.tr_slider && (
                    <div
                      className="slider"
                      style={{
                        border: `2px solid ${color}`,
                        boxShadow: `0 0 5px 0 ${color}`,
                      }}
                    >
                      <ul>
                        {images.tr_slider.map((ev) => (
                          <li style={{ backgroundImage: `url(${ev})` }}></li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={1}
          >
            {/* {pensiones && (
              <Grid item xs={12}>
                <Card style={{ boxShadow: `0 0 5px 0 ${color}` }}>
                  <CardContent>
                    <List>
                      {Object.entries(pensiones).map((ev) => (
                        <ListItem divider={true} button>
                          <ListItemIcon>
                            <Avatar
                              style={{
                                color: color,
                                backgroundColor: "white",
                                border: `2px solid ${color}`,
                              }}
                            >
                              <PlaylistAddCheckIcon />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body1" color="textSecondary">{ev[0]}</Typography>} />
                          <ListItemSecondaryAction>
                            <sup>S/</sup>
                            <b>{ev[1]}</b>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            )} */}
            {brindamos && (
              <Grid item xs={12}>
                <Card style={{ boxShadow: `0 0 5px 0 ${color}` }}>
                  <CardContent>
                    <List>
                      {brindamos.map((ev) => (
                        <ListItem divider={true} button>
                          <ListItemIcon>
                            <Avatar
                              style={{
                                color: color,
                                backgroundColor: "white",
                                border: `2px solid ${color}`,
                              }}
                            >
                              <CheckIcon />
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography color="textSecondary" variant="body2">
                                {ev}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Actividades color={color} />
        <Grid xs={12}>
          {plataformas && plataformas.length && (
            <Card style={{ boxShadow: `0 0 5px 0 ${color}` }}>
              <Grid container alignItems="center" justify="center">
                {plataformas.map((ev) => (
                  <Box p={2}>
                    <Typography variant="body1" color="textSecondary">
                      {ev == "Whatsapp" ? (
                        <div align="center">
                          <WhatsAppIcon className={classes.icon} />
                          <Typography variant="body2" color="textSecondary">
                            {ev}
                          </Typography>
                        </div>
                      ) : ev == "Zoom" ? (
                        <div align="center">
                          <img src={Zoom} height="50px" />
                          <Typography variant="body2" color="textSecondary">
                            {ev}
                          </Typography>
                        </div>
                      ) : ev == "Napclass" ? (
                        <div align="center">
                          <img src={NapClass} height="50px" />
                          <Typography variant="body2" color="textSecondary">
                            {ev}
                          </Typography>
                        </div>
                      ) : (
                        <div align="center">
                          <Typography variant="body2" color="textSecondary">
                            {ev}
                          </Typography>
                        </div>
                      )}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
    </Slide>
  );
};

export default DetailsLevel;
