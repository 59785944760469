import React from "react";
import {
  Card,
  makeStyles,
  CardHeader,
  Avatar,
  IconButton,
  CardMedia,
  Typography,
  CardContent,
  CardActions,
  Grid,
  Slide,
} from "@material-ui/core";
import { ImLocation2 } from "react-icons/im";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { ImPhone } from "react-icons/im";
import { BiTimeFive } from "react-icons/bi";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "70.25%", // 16:9
  },
  expand: {
    marginLeft: "auto",
    border: "1px solid #ff6b6b",
    color: "#ff6b6b",
  },
  avatar: {
    backgroundColor: "#ff6b6b",
  },
  card: {
    height: "100%",
    "&:hover": {
      boxShadow: "0px 9px 10px -6px rgba(0,0,0,0.5)",
      transition: ".25s",
    },
  },
}));

const Sede = (props) => {
  const { data, index } = props;
  const classes = useStyles();

  const numberPhones = data.phone.split(",");

  return (
    <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1000}>
      <Grid item xs={12} sm={6} lg={4}>
        <Card className={classes.card}>
          <CardHeader
            avatar={<Avatar className={classes.avatar}>{index}</Avatar>}
            title={<b>{data.name.toUpperCase()}</b>}
            /* subheader="September 14, 2016" */
          />
          <CardMedia
            className={classes.media}
            image={data.image}
            title={data.name}
          />
          <CardContent>
            {data.address && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ display: "flex", fontSize: 13 }}
              >
                <ImLocation2 style={{ fontSize: 25, marginRight: 5 }} />
                <p style={{ width: "calc(100% - 40px)" }}>
                  {data.address.toUpperCase()}
                </p>
              </Typography>
            )}
            {numberPhones.length > 0 &&
              numberPhones.map((ev) => (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ display: "flex" }}
                >
                  <ImPhone style={{ fontSize: 22, marginRight: 9 }} />
                  <p style={{ width: "calc(100% - 40px)" }}>
                    <a href={`tel:${ev}`}>{ev}</a>
                  </p>
                </Typography>
              ))}
            {data.horario && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ display: "flex" }}
              >
                <BiTimeFive style={{ fontSize: 22, marginRight: 9 }} />
                <p style={{ width: "calc(100% - 40px)" }}>{data.horario}</p>
              </Typography>
            )}
          </CardContent>
          {data.facebook && (
            <CardActions>
              {data.facebook.split(",").map((ev) => (
                <IconButton
                  className={classes.expand}
                  href={ev}
                  target="_blank"
                >
                  {ev.includes("instagram") ? <FaInstagram /> : <FaFacebookF />}
                </IconButton>
              ))}
            </CardActions>
          )}
        </Card>
      </Grid>
    </Slide>
  );
};

export default Sede;
