import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
} from "@material-ui/core";
import { BiBookAlt } from "react-icons/bi";
import { FaCalculator, FaMusic } from "react-icons/fa";
import { MdGamepad } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "50px",
  },
  text: {
    width: "calc((100%) - 60px)",
    padding: "0 10px",
  },
}));

const Reforzamiento = (props) => {
  const { color } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} align="center">
          <br />
          <Typography variant="h4" color="textPrimary">
            <b>Lo que reforzamos</b>
          </Typography>
          <Typography variant="body1" color="textSecondary">
            <b>Nos preocupamos por tu correcto aprendizaje</b>
          </Typography>
          <br /> <br />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item className={classes.icon}>
                  <BiBookAlt style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>INTRODUCIÓN A LA LECTURA</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Mediante cuentos y obras introducimos y reforzamos a los
                    estudiantes en la lectura y la comunidad oral y escrita.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item className={classes.icon}>
                  <FaCalculator style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>LÓGICO MATEMÁTICO</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Desarrollamos habilidades en actividades lúdicas que
                    desarrollen su capacidad lógica y matemática.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item className={classes.icon}>
                  <MdGamepad style={{ color: color, fontSize: 40 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>JUEGOS Y DEPORTE</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Buscamos que nuestros estudiantes tengan un desarrollo
                    motriz adecuado con conocimientos del cuidado del cuerpo.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ borderTop: `8px solid ${color}`, width: "100%" }}>
            <CardContent>
              <Grid container>
                <Grid item className={classes.icon}>
                  <FaMusic style={{ color: color, fontSize: 35 }} />
                </Grid>
                <Grid item className={classes.text}>
                  <Typography variant="body1" color="textPrimary">
                    <b>CREATIVIDAD Y DESENVOLVIMIENTO ARTÍSTICO</b>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Creamos un ambiente en el que nuestros estudiantes posean el
                    talento para divertirse, crecer y desarrollarse con talentos
                    en áreas de artes de todo tipo.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          <br />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Reforzamiento;
