import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { GiOpenBook } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
  cover: {
    width: 151,
  },
  title: {
    borderRadius: 20,
    textAlign: "center",
    fontSize: 35,
    fontFamily: "cursive",
  },
  backTop: {
    height: 100,
    width: "100%",
  },
  ci: {
    width: "100%",
    textAlign: "center",
    marginTop: "-50px",
  },
  img: {
    width: "70",
    height: "70",
    borderRadius: "50%",
    border: "5px solid #f2f2f2",
    boxShadow: `1px 2px 10px -2px #aaa`,
    fontSize: 100,
    backgroundColor: "#fff",
  },
}));

const CardComponent = (props) => {
  const { title, subtitle, titleColor, content, shadow } = props;
  const classes = useStyles();

  return (
    <Card
      style={{
        boxShadow: `1px 2px 10px -4px ${shadow}`,
        height: "100%",
        maxWidth:400
      }}
    >
      <div className={classes.backTop} style={{ backgroundColor: shadow }} />
      <div className={classes.ci}>
        <GiOpenBook className={classes.img} style={{ color: shadow }} />
      </div>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            style={{ color: shadow }}
            variant="h5"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            <b>{title}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <CardContent>
            <Typography
              variant="h6"
              color="inherit"
              style={{ color: titleColor, letterSpacing: 1 }}
            >
              {subtitle}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              color="textSecondary"
              style={{ letterSpacing: 1.2 }}
            >
              {content}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardComponent;
