import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import moment from "moment";

const ViewNotice = (props) => {
  const { visible, setVisible, data } = props;
  return (
    <Dialog
      fullScreen
      open={visible}
      onClose={() => setVisible({ false: false })}
    >
      <DialogTitle align="center">
        <Typography variant="h6" color="textPrimary">
          <b>{data.title.toUpperCase()}</b>
          <Typography variant="body2" color="textSecondary">
            {moment(data.date).format("[Publicado el] D [de] MMMM [del] YYYY")}
          </Typography>
          <Typography variant="body2" color="textPrimary" dangerouslySetInnerHTML={{__html: data.body}} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <iframe
          src={data.image}
          width="100%"
          style={{ height: "calc(100% - 5px)" }}
          title="pdf element"
          frameborder="0"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setVisible({ false: false })}
        >
          cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewNotice;
