import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  makeStyles,
  Divider,
} from "@material-ui/core";
import ViewNotice from "./ViewNotice";
import { ImFilePdf } from "react-icons/im";
import moment from "moment";
import Spinner from "./Spinner";
import api from "../info/api";

const useStyles = makeStyles((theme) => ({
  list: {
    height: "100%",
    backgroundImage:
      "linear-gradient(to left bottom, #ffffff, #fdfafd, #fdf4f9, #feeef3, #ffe9e9)",
    transition: ".5s",
    "&:hover": {
      backgroundImage:
        "linear-gradient(to left bottom, #ffe9e9, #ffe9e9, #feeef3, #feeef3, #ffe9e9)",
    },
  },
  border: {
    width: 100,
    height: 7,
    backgroundColor: "#0d6368",
    marginLeft: 0,
    marginTop: 0,
  },
}));

const News = () => {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [viewNotice, setViewNotice] = useState({
    data: "",
    open: false,
  });
  // const newsQuery = {
  //   collection: "news",
  //   // doc: "f1fi0GUxyLaTKZarXlZR",
  //   // subcollections: [{ collection: "news" }],
  // };
  // useFirestoreConnect((props) => [newsQuery]);
  // const todos = useSelector((news) => news.firestore.ordered.news);

  // useEffect(() => {
  //   if (todos !== undefined && todos.length !== 0) {
  //     setNews(todos);
  //   } else {
  //     setNews([]);
  //   }
  // }, [todos]);

  news.sort(function (a, b) {
    a = new Date(a.date);
    b = new Date(b.date);
    return a < b ? -1 : a > b ? 1 : 0;
  });

  let years = [];

  for (let i = 0; i < news.length; i++) {
    let finder = years.find((el) => el === moment(news[i].date).format("YYYY"));
    if (!finder) {
      years.push(moment(news[i].date).format("YYYY"));
    }
  }

  useEffect(() => {
    obtainData();
  }, []);

  const obtainData = () => {
    try {
      fetch(`${api}/notice/maac`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => setNews(data));
    } catch (error) {
    } finally {
    }
  };

  // useEffect(() => {
  //   if (news.length) {
  //     obtainData();
  //   }
  // }, [news]);

  // const obtainData = async () => {
  //   try {
  //     const res = await fetch(
  //       "http://192.168.1.119:1500/api/notice/massive/maac",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(news),
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => console.log(data));
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     console.log("FIN");
  //   }
  // };

  return (
    <div style={{ padding: "50px 5%" }}>
      {viewNotice.open && (
        <ViewNotice
          visible={viewNotice.open}
          setVisible={setViewNotice}
          data={viewNotice.data}
        />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} align="center">
          {news.length > 0 ? (
            years.reverse().map((event) => (
              <>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  align="left"
                  className="mt-5"
                >
                  <b>{event}</b>
                  <hr className={classes.border} />
                </Typography>
                <List dense>
                  <Grid container spacing={2}>
                    {news.map(
                      (n) =>
                        moment(n.date).format("YYYY") === event && (
                          <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <ListItem
                              button
                              onClick={() =>
                                setViewNotice({ open: true, data: n })
                              }
                              className={classes.list}
                            >
                              <ListItemAvatar>
                                <Avatar style={{ backgroundColor: "#ff6b6b" }}>
                                  <ImFilePdf />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={<b>{n.title.toUpperCase()}</b>}
                                secondary={`Publicado el ${moment(
                                  n.date
                                ).format("DD [de] MMMM")}`}
                              />
                            </ListItem>
                            <Divider />
                          </Grid>
                        )
                    )}
                  </Grid>
                </List>
              </>
            ))
          ) : (
            <Spinner />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default News;
