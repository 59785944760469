import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import NavbarColorST from "../components/NavbarColorST";
import Footer from "../components/Footer.jsx";
import News from "../components/News.jsx";
import Header from "../components/Header";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
}));

const Noticias = () => {
  const classes = useStyles();
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>

      <Header title="Noticias" />
      <div ref={inputRef} className={classes.root}>
        <News />
      </div>
      <Footer />
    </>
  );
};

export default Noticias;
