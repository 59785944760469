import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import NavbarColorST from "../components/NavbarColorST";
import Footer from "../components/Footer";
import Sede from "../components/Sede";
import Header from "../components/Header";
import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import Spinner from "../components/Spinner";

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "sticky",
    zIndex: 100,
    width: "100%",
    height: "auto",
    top: 0,
    left: 0,
    marginBottom: 0,
  },
}));

const Sedes = (props) => {
  const classes = useStyles();
  const [dataSedes, setdataSedes] = useState([]);
  const [bkColor, setBkColor] = useState(false);
  const inputRef = useRef();

  const scrollHandler = (_) => {
    if (inputRef.current) {
      setBkColor(inputRef.current.getBoundingClientRect().top < 225);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", () => scrollHandler);
    };
  }, []);

  const newsQuery = {
    collection: "sedes",
    // doc: "f1fi0GUxyLaTKZarXlZR",
    // subcollections: [{ collection: "sedes" }],
  };

  useFirestoreConnect((props) => [newsQuery]);

  const todos = useSelector((sedes) => sedes.firestore.ordered.sedes);

  useEffect(() => {
    if (todos !== undefined && todos.length !== 0) {
      let arreglado = todos.map((e) => {
        return {
          name: e.name,
          horario: e.horario,
          facebook: e.facebook,
          address: e.address,
          phone: e.phone,
          image: e.image,
          aulaVirtual: e.aulaVirtual,
          modal: false,
        };
      });
      setdataSedes(arreglado);
    } else {
      setdataSedes([]);
    }
  }, [todos]);

  return (
    <>
      <div className={classes.menu}>
        {bkColor ? <NavbarColorST /> : <Navbar />}
      </div>
      <Header title="Sedes" />
      <div ref={inputRef} className={classes.root}>
        <Container style={{ marginTop: 100 }}>
          <Grid container spacing={2}>
            {dataSedes.length > 0 ? (
              dataSedes.map((e, index) => <Sede data={e} index={index + 1} />)
            ) : (
              <Spinner />
            )}
          </Grid>
        </Container>
      </div>
      <br /> <br /> <br />
      <Footer></Footer>
    </>
  );
};

export default Sedes;
