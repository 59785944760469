import React from "react";
import { makeStyles } from "@material-ui/core";
import CarouselUI from "react-material-ui-carousel";
import Paper from "@material-ui/core/Paper";
import maac1 from "../assets/img/maac1.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    height: "100vh",
    border: "1px solid red",
  },

  contentImage: {
    height: "95vh",
    width: "100%",
    overflowY: "hidden",
  },
  imagen: {
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  divs: {
    backgroundColor: "rgba(0,0,0,0.6)",
  },
}));

const Carouselx = () => {
  const imagenes = [
    maac1,
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/Im5td52g.jpeg",
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/oWtsMFvg.jpeg",
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/PllbZ7Gw.jpeg",
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/qFFkENSA.jpeg",
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/Xavlr5kw.jpeg",
    "https://maacfiles.s3.us-east-2.amazonaws.com/carousel/YVfoSjew.jpeg",
  ];

  return (
    <>
      <CarouselUI
        next={(next, active) => {}}
        prev={(prev, active) => {}}
        animation="slide"
        stopAutoPlayOnHover={false}
        indicators={false}
        interval={7000}
      >
        {imagenes.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </CarouselUI>
    </>
  );
};

function Item(props) {
  const classes = useStyles();
  return (
    <Paper className={classes.contentImage}>
      <div
        className={classes.imagen}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${props.item}) `,
        }}
      ></div>
    </Paper>
  );
}

export default Carouselx;
